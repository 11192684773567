//External
import { useEffect, useMemo } from "react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";

//Views
import PublicView from "./views/PublicView";
import Portal from "./Portal";

//Page
import CompCardPage from "./components/page/public/CompCardPage";

//Theming
import "./App.scss";
import GlobalFonts from "./theme/globalFonts";
import { useAppSelector } from "./store/store";
import { loggedInUserSelector } from "./store/selectors/authSelector";

function App() {
    //Hookes
    const navigate = useNavigate();    
    const location = useLocation();

    //Selectors
    const isAuthenticated = useAppSelector(
        (state) => state.auth.isAuthenticated
    );
    const user = useAppSelector(loggedInUserSelector);    

    // Public Paths
    const publicPaths = useMemo(() => {
        return [
            "/new-profile/:stage",
            "/apply-now/*",
            "/how-we-work",
            "/portal",
            "/about-us",
            "/our-work",
            "/testimonials",
            "/apply",
            "/reset",
            "/reset/*",
            "/create"
        ];
    }, []);

    const isCompCardPage = matchPath("/role/:exportCode", location.pathname);
    const isEventCardPage = matchPath("/event/:exportCode", location.pathname);

    useEffect(() => {
        const isPublicPage = publicPaths.find((path) =>
            matchPath(
                {
                    path: path,
                },
                location.pathname
            )
        );

        if (isAuthenticated && user?.role === "Actor" && matchPath({ path: "/portal" }, location.pathname)) {
            navigate("/portal/profile");
        } else if (!isAuthenticated && matchPath({ path: "/new-profile" }, location.pathname)) {
            navigate("/new-profile/1");
        } else if (
            isAuthenticated &&
            user?.role === "Admin" &&
            matchPath({ path: "/portal" }, location.pathname)
        ) {
            navigate("/portal/search");
        } else if(isAuthenticated && user?.role === "User" && matchPath({ path: "/portal"}, location.pathname)){
            navigate("/portal/search");
        } else if (!isAuthenticated && !isPublicPage && !isCompCardPage && !isEventCardPage) {
            navigate("/");
        }
    }, [isAuthenticated, user, location.pathname, navigate, publicPaths, isCompCardPage, isEventCardPage]);

    useEffect(() => {
        document.title = "P.G's Agency";
    });

    return (
        <>
            <GlobalFonts />
            <div className="App" style={{ height: "100%" }}>
                {!isAuthenticated && !isCompCardPage && !isEventCardPage && <PublicView />}

                {isAuthenticated && !isCompCardPage && !isEventCardPage && <Portal />}

                {isCompCardPage && (
                    <CompCardPage
                        exportCode={isCompCardPage.params.exportCode ?? ""}
                    />
                )}
                {
                    isEventCardPage && (
                        <CompCardPage
                            isEvent={true}
                            exportCode={isEventCardPage.params.exportCode ?? ""}
                        />
                    )
                }
            </div>
        </>
    );
}

export default App;
