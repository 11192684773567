import { createSlice } from "@reduxjs/toolkit";
import { clearActorConfirmationError, clearCalendarExport, setActorConfirmation, createCalendarEvent, deleteCalendarEvent, deleteCalendarEventLocally, 
    getCalendarEvents, getSearchActors, getStaffAvailability, resetCalendarSlice, resetDeleteSuccess, resetUpdateSuccess, searchActors, searchPhotographyAttendees, 
    setAvailability, updateCalendarEvent, clearActorConfirmationSuccess } from "../actions/calendarActions";
import { ICalendarState, PGEvent } from "../model/calendarModel";
import { GetMinuteOfDay } from "../../utility/date";
import EventActor from "../../model/EventActor";


const initialState: ICalendarState = {
    loading: false,
    updateEventLoading: false,
    updateEventError: false,
    updateEventSuccess: false,
    deleteEventLoading: false,
    deleteEventError: false,
    deleteEventSuccess: false,
    settingActorConfirmation: false,
    actorConfirmationError: false,
    actorConfirmationSuccess: false,
    events: [],
    actorSearchResults: [],
    isSearchingActors: {
        status: false,
        index: -1
    },
    isLoadingInterviews: false,    
    takenStartDates: [],
    takenInProgressDates: [],
    takenEndDates: [],
    isSearchingPhotography: false,
    photographySearchError: false,
    photographyResults: [],
    exportActorsLoading: false,
    exportActors: []
}

const calendarSlice = createSlice({
    name: "calendar",
    initialState: initialState,
    reducers: {

    },
    extraReducers: ({ addCase }) => {
        addCase(getCalendarEvents.pending, (state, action) => {
            state.loading = true;
        })

        addCase(getCalendarEvents.rejected, (state) => {
            state.updateEventError = true;
        });

        addCase(getCalendarEvents.fulfilled, (state, action) => {
            if(action.payload != null) {
                state.loading = false;
                state.events = action.payload.events.map(e => {
                    if(e.resources !== null && e.resources.assignedActors != null && e.resources.assignedActors.entries !== null && e.resources.assignedActors.entries.length > 0) {
                        return {
                            allDay: e.isAllDay,
                            start: new Date(e.start),
                            end: new Date(e.end),
                            title: e.title,
                            resource: { 
                                ...e.resources, 
                                jobId: e.resources.job?.id ?? null,
                                assignedActors: {
                                    ...e.resources.assignedActors,
                                    entries: e.resources.assignedActors.entries.map((x:EventActor) => ({
                                        ...x,
                                        isExisting: true
                                    }))
                                }
                           },
                           eventCode: e.eventCode  
                        } as PGEvent
                    }

                    return { 
                        allDay: e.isAllDay,
                        start: new Date(e.start),
                        end: new Date(e.end),
                        title: e.title,
                        resource: e.resources,
                        eventCode: e.eventCode
                    } as PGEvent
                });
            }
        });

        addCase(resetCalendarSlice, (state) => {
            Object.assign(state, initialState);
        })


        addCase(updateCalendarEvent.pending, (state) => {
            state.updateEventLoading = true;
            state.updateEventError = false;
        });

        addCase(updateCalendarEvent.rejected, (state) => {
            state.updateEventError = true;
            state.updateEventLoading = false;
        });

        addCase(updateCalendarEvent.fulfilled, (state) => {
            state.updateEventLoading = false;
            state.updateEventSuccess = true;
        });

        addCase(resetUpdateSuccess, (state) => {
            state.updateEventSuccess = false;
        });

        addCase(deleteCalendarEvent.pending, (state) => {
            state.deleteEventLoading = true;
            state.deleteEventError = false;
        });

        addCase(deleteCalendarEvent.rejected, (state) => {
            state.deleteEventLoading = false;
            state.deleteEventError = true;
        });

        addCase(deleteCalendarEvent.fulfilled, (state) => {
            state.deleteEventLoading = false;
            state.deleteEventSuccess = true;
        });

        addCase(deleteCalendarEventLocally, (state, action) => {
            state.events = state.events.filter(x => x.resource && x.resource.eventId !== action.payload.id);
        });

        addCase(resetDeleteSuccess, (state) => {
            state.deleteEventSuccess = false;
        });

        addCase(createCalendarEvent.pending, (state) => { 
            state.updateEventLoading = false;
            state.updateEventError = false;
        });

        addCase(createCalendarEvent.rejected, (state) => {
            state.updateEventLoading = false;
            state.updateEventError = true;
        });

        addCase(createCalendarEvent.fulfilled, (state) => {
            state.updateEventLoading = false;
            state.updateEventSuccess = true;
        });

        addCase(setAvailability.pending, (state) => { 
            state.updateEventLoading = true;
            state.updateEventError = false;
        });

        addCase(setAvailability.rejected, (state) => {
            state.updateEventLoading = false;
            state.updateEventError = true;
        });

        addCase(setAvailability.fulfilled, (state) => {           
            state.updateEventLoading = false;
            state.updateEventSuccess = true;
        });

        addCase(searchActors.pending, (state, action) => {
            state.isSearchingActors.status = true;
            state.isSearchingActors.index = action.meta.arg.index;
            state.actorSearchResults = [];
        });

        addCase(searchActors.rejected, (state) => {
            state.isSearchingActors.status = false;
            state.isSearchingActors.index = -1;
            state.actorSearchResults = [];
        });

        addCase(searchActors.fulfilled, (state, action) => {
            state.isSearchingActors.status = false;
            state.isSearchingActors.index = -1;
            state.actorSearchResults = action.payload.data;
        });

        addCase(getStaffAvailability.pending, (state) => {
            state.isLoadingInterviews = true;            
            state.takenStartDates = [];
            state.takenEndDates = [];
        });

        addCase(getStaffAvailability.rejected, (state) => {
            state.isLoadingInterviews = false;
        });

        addCase(getStaffAvailability.fulfilled, (state, action) => {
            state.isLoadingInterviews = false;            
            const newEnd: number[] = [];
            const newInprogress: number[] = [];
            const newStart: number[] = [];
            action.payload.takenSlots.forEach(event => {
                if(event.end != null && event.start != null){
                    const eventStart = new Date(event.start);
                    const eventEnd = new Date(event.end);

                    var endMinuteOfDay = GetMinuteOfDay(eventEnd.getHours(), eventEnd.getMinutes());
                    var startMinuteOfDay = GetMinuteOfDay(eventStart.getHours(), eventStart.getMinutes());

                    newEnd.push(endMinuteOfDay);
                    newStart.push(startMinuteOfDay);
                    startMinuteOfDay += 15;
                    while(startMinuteOfDay < endMinuteOfDay) {
                        newInprogress.push(startMinuteOfDay);
                        startMinuteOfDay += 15;
                    }
                }
            });

            state.takenStartDates = newStart;
            state.takenInProgressDates = newInprogress;
            state.takenEndDates = newEnd;

        });

        addCase(searchPhotographyAttendees.pending, (state) => {
            state.isSearchingPhotography = true;
            state.photographySearchError = false;
        });

        addCase(searchPhotographyAttendees.rejected, (state) => {
            state.isSearchingPhotography = false;
            state.photographySearchError = true;
        });

        addCase(searchPhotographyAttendees.fulfilled, (state, action) => {
            state.isSearchingPhotography = false;
            state.photographySearchError = false;
            state.photographyResults = action.payload.data ?? [];
        });

        addCase(getSearchActors.pending, (state, action) => {
            state.exportActorsLoading = true;
        });

        addCase(getSearchActors.rejected, (state, action) => {
            state.exportActorsLoading = false;
        });

        addCase(getSearchActors.fulfilled, (state, action) => {
            state.exportActorsLoading = false;
            state.exportActors = action.payload;
        });

        addCase(clearCalendarExport, (state) => {
            state.exportActors = [];
        });

        addCase(setActorConfirmation.pending, (state, action) => {
            state.settingActorConfirmation = true;
        });

        addCase(setActorConfirmation.rejected, (state, action) => {
            state.settingActorConfirmation = false;
            state.actorConfirmationError = true;
        });

        addCase(setActorConfirmation.fulfilled, (state, action) => {
            state.settingActorConfirmation = false;
            state.actorConfirmationSuccess = true;
            var events = [...state.events];
            var eventIndex = events.findIndex(event => event.resource !== null && event.resource.eventId === action.meta.arg.eventId);
            if(eventIndex !== -1) {
                var newEvent = { ...events[eventIndex]}
                if(newEvent.resource.assignedActors !== null && newEvent.resource.assignedActors.entries !== null && newEvent.resource.assignedActors.entries.length > 0){
                    var actorIndex = newEvent.resource.assignedActors.entries.findIndex((actor: EventActor) => actor.id === action.meta.arg.eventActorsEntryId);
                    if(actorIndex !== -1){
                        newEvent.resource.assignedActors.entries[actorIndex] = { 
                            ...newEvent.resource.assignedActors.entries[actorIndex],
                            hasActorConfirmed: action.meta.arg.isConfirmed
                        }

                        events[eventIndex] = newEvent;
                        state.events = events;
                    }

                }
            }
        });

        addCase(clearActorConfirmationError, (state) => {
            state.actorConfirmationError = false;
        });
        
        addCase(clearActorConfirmationSuccess, (state) => {
            state.actorConfirmationSuccess = false
        });
    }
})

export default calendarSlice.reducer;