import { CreateActorGroupRequest, CreateActorGroupResponse, DeleteActorGroupRequest, GetActorGroupsDataResponse, GetActorGroupsDataRequest, 
    GetActorGroupsRequest, GetActorGroupsResponse, GetCompCardRequest, GetCompCardResponse, IBookActorGroupRequest, IBookActorGroupResponse, 
    SearchActorGroupsRequest, SearchActorGroupsResponse, UpdateActorGroupResponse, UpdateActorGroupsRequest, 
    GetEventCardRequest, GetEventCardResponse } from "../model/actorGroupModel"
import { apiCall, apiCallNoResponse } from "./sharedAsync"

var getActorGroupAbortController: AbortController | undefined = undefined;
var getActorGroupDataAbortController: AbortController | undefined = undefined;
var bookingAbortController: AbortController | undefined = undefined;
var searchAbortController: AbortController | undefined = undefined;

export const apiGetActorGroups = async (payload: GetActorGroupsRequest, token: string, rejectWithValue: (value: string) => void) => {
    if(getActorGroupAbortController !== undefined) {
        getActorGroupAbortController.abort();
    }
    if(searchAbortController !== undefined){
        searchAbortController.abort();
    }
    getActorGroupAbortController = new AbortController();
    return apiCall<GetActorGroupsResponse, void>(token, `/actorgroups/${payload.pageIndex}/${payload.pageSize}/` + (payload.sortColumn && payload.sortOrder ? `${payload.sortColumn}/${payload.sortOrder}` : "") , "GET", rejectWithValue, "Unable to retrieve roles.", undefined, getActorGroupAbortController.signal);
}

export const apiGetActorGroupsData = async (payload: GetActorGroupsDataRequest, token: string, rejectWithValue: (value: string) => void) => {
    if(getActorGroupDataAbortController !== undefined) {
        getActorGroupDataAbortController.abort();
    }
    getActorGroupDataAbortController = new AbortController();
    return apiCall<GetActorGroupsDataResponse, GetActorGroupsDataRequest>(token, `/actorgroup/getdata`, "POST", rejectWithValue, "Unable to retrieve role data.", { actorGroupIds: payload.actorGroupIds } as GetActorGroupsDataRequest, getActorGroupDataAbortController.signal);
}

export const apiCancelGetActorGroupsData = () => {
    if(getActorGroupDataAbortController !== undefined) {
        getActorGroupDataAbortController.abort();
    }
}

export const apiCreateActorGroup = async (payload: CreateActorGroupRequest, token: string, rejectWithValue: (value: string) => void) => {    
    return apiCall<CreateActorGroupResponse, CreateActorGroupRequest>(token, "/actorgroup/create", "POST", rejectWithValue, "Unable to create role.", payload);
}

export const apiSearchActorGroups = async (payload: SearchActorGroupsRequest, token: string, rejectWithValue: (value: string) => void) => {
    if(searchAbortController !== undefined) {
        searchAbortController.abort();
    }
    if(getActorGroupAbortController !== undefined) {
        getActorGroupAbortController.abort();
    }
    searchAbortController = new AbortController();
    return apiCall<SearchActorGroupsResponse, void>(token, `/actorgroup/search/${payload.name}`, "GET", rejectWithValue, "Unable to retrieve role search results", undefined, searchAbortController.signal);
}

export const apiUpdateActorGroup = async (payload: UpdateActorGroupsRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<UpdateActorGroupResponse, UpdateActorGroupsRequest>(token, "/actorgroup/update", "PATCH", rejectWithValue, "Unable to update actor group", payload);
}

export const apiDeleteActorGroup = async (payload: DeleteActorGroupRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCallNoResponse<DeleteActorGroupRequest>(token, `/actorgroup/delete/${payload.actorGroupId}`, "DELETE", rejectWithValue, "Failed to delete actor group");
}

export const apiGetCompCard = async (payload: GetCompCardRequest, rejectWithValue: (value: string) => void) => {
    return apiCall<GetCompCardResponse, GetCompCardRequest>("", `/compcard/${payload.exportCode}`, "GET", rejectWithValue, "Unable to retrieve comp card");
}

export const apiGetEventCard= async (payload: GetEventCardRequest, rejectWithValue: (value: string) => void) => {
    return apiCall<GetEventCardResponse, GetEventCardRequest>("", `/event/${payload.eventCode}`, "GET", rejectWithValue, "Unable to retrieve comp card");
}

export const apiBookActorGroup = async (payload: IBookActorGroupRequest, token: string, rejectWithValue: (value: string) => void) => {
    if(bookingAbortController !== undefined) {
        bookingAbortController.abort();
    }
    bookingAbortController = new AbortController();
    return apiCall<IBookActorGroupResponse, IBookActorGroupRequest>(token, `/actorgroup/book`, "POST", rejectWithValue, "Failed to book actor group", payload, bookingAbortController.signal);
}