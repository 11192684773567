import { createListenerMiddleware } from "@reduxjs/toolkit";
import { GetNextMonthEnd, GetPrevMonthStart } from "../../utility/date";
import { createCalendarEvent, deleteCalendarEvent, deleteCalendarEventLocally, getCalendarEvents, setAvailability, updateCalendarEvent } from "../actions/calendarActions";
import { AppDispatch } from "../store";
import { createJob, markInvoiced, searchJobs } from "../actions/jobActions";

const calendarMiddleware = createListenerMiddleware();

calendarMiddleware.startListening({
    actionCreator: updateCalendarEvent.fulfilled,
    effect: async (action, listenerApi) => {
        //cancel other running instances
        listenerApi.cancelActiveListeners();
        var startDate = new Date(action.meta.arg.start ?? new Date());
        (listenerApi.dispatch as AppDispatch)(getCalendarEvents({ startRange: GetPrevMonthStart(startDate), endRange: GetNextMonthEnd(startDate)}));
    }
});

calendarMiddleware.startListening({
    actionCreator: setAvailability.fulfilled,
    effect: async (action, listenerApi) => {
        //cancel other running instances
        listenerApi.cancelActiveListeners();
        var startDate = new Date(action.meta.arg.availabilities[0].date);
        (listenerApi.dispatch as AppDispatch)(getCalendarEvents({ startRange: GetPrevMonthStart(startDate), endRange: GetNextMonthEnd(startDate)}));
    }
})

calendarMiddleware.startListening({
    actionCreator: deleteCalendarEvent.fulfilled,
    effect: async (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        (listenerApi.dispatch as AppDispatch)(deleteCalendarEventLocally({ id: action.meta.arg.Id}))
    }
})


calendarMiddleware.startListening({
    actionCreator: createCalendarEvent.fulfilled,
    effect: async (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        var startDate =  new Date(action.payload.event.start);       
        (listenerApi.dispatch as AppDispatch)(getCalendarEvents({ startRange: GetPrevMonthStart(startDate), endRange: GetNextMonthEnd(startDate) }));
    }
});

calendarMiddleware.startListening({
    actionCreator: createJob.fulfilled,
    effect: async (action, listenerApi) => {
        listenerApi.cancelActiveListeners();            
        (listenerApi.dispatch as AppDispatch)(searchJobs({ searchTerm: action.meta.arg.name }));
    }
});

calendarMiddleware.startListening({
    actionCreator: markInvoiced.fulfilled,
    effect: async (action, listenerApi) => {
        if(action.meta.arg.eventId !== undefined){
            var event = (listenerApi.getState() as RootState).calendar.events.find(x => x.resource && x.resource.eventId === action.meta.arg.eventId);
            if(event !== undefined){
                listenerApi.cancelActiveListeners();
                var rangeDate = event.start ? new Date(event.start) : new Date();
                (listenerApi.dispatch as AppDispatch)(getCalendarEvents({ startRange: GetPrevMonthStart(rangeDate), endRange: GetNextMonthEnd(rangeDate) }));
            }
        }
    }
})

export default calendarMiddleware.middleware;