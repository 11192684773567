import React, { useCallback, useEffect, useState } from "react";

// -- Types
import { useAppDispatch, useAppSelector } from "../../../store/store";

// External
import "../../../../node_modules/react-bootstrap-typeahead/css/Typeahead.min.css";

// -- Types
import { Event } from "react-big-calendar";
import {
    createCalendarEvent,
    deleteCalendarEvent,
    setAvailability,
    updateCalendarEvent,
} from "../../../store/actions/calendarActions";
import { Job } from "../../../store/model/jobModel";
import EventActor from "../../../model/EventActor";
import { loggedInUserSelector } from "../../../store/selectors/authSelector";
import { editActorSelector } from "../../../store/selectors/actorSelector";
import { GetDaysArray } from "../../../utility/date";
import { Availability, PGEvent } from "../../../store/model/calendarModel";
import moment from "moment";
import { isBefore, isEqual } from "date-fns";
import OverlayBg from "./subcomponents/OverlayBg";
import AdminEventModalContent from "./AdminEventModalContent";
import EventTypes, { getEventTypeId, getEventTypeTag } from "../../../model/EventTypes";
import ActorEventModalContent from "./ActorEventModalContent";



interface ICalendarEventModalProps {
    setSelectedEvent: React.Dispatch<React.SetStateAction<Event | null>>;
    event: PGEvent;
    isNavOpen: boolean;
    defaultEdit?: boolean;
}

const NewCalendarEventModal: React.FC<ICalendarEventModalProps> = ({
    event,
    isNavOpen,
    setSelectedEvent,
    defaultEdit = false,
}) => {
    const dispatch = useAppDispatch();
    const eventTypes = ["Job", "Photography", "Actor Visit"]

    const [newEvent, setNewEvent] = useState<Event>({...event, start: event.start != null ? event.start : new Date(), end: event.end != null ? event.end : new Date(new Date().getTime() + 15 * 60000)})
    const [editMode, setEditMode] = useState<boolean>(defaultEdit);
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);
    const [clearJob, setClearJob] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [timesheetError, setTimesheetError] = useState<string>("");
    
    const [selectedEventType, setSelectedEventType] = useState<number>(event.resource && event.resource.eventType ? eventTypes.indexOf(getEventTypeTag(event.resource.eventType)) : 0);    

    const user = useAppSelector(loggedInUserSelector);
    const actor = useAppSelector(editActorSelector);

    const handleDiscard = () => {
        setNewEvent({...event})
        if (defaultEdit) {
            setSelectedEvent(null);
        } else {            
            setEditMode(false);
        }
    }

    const validate = useCallback(() => {
        var startDate = newEvent.start;
        var endDate = newEvent.end;
        var allDay = newEvent.allDay ?? false;
        var eventActors = newEvent.resource && newEvent.resource.assignedActors && newEvent.resource.assignedActors.entries ? newEvent.resource.assignedActors.entries : null;
        var title = newEvent.title + ""; 
        if(newEvent.resource === null) {
            setIsValid(false);
            return false;
        }

        if(!title || title.toString().length === 0){
            setIsValid(false);
            return false;
        }
        
        if(!(
                startDate && 
                endDate &&
                (isBefore(new Date(startDate), new Date(endDate)) || (allDay && isEqual(new Date(startDate), new Date(endDate))))
            )
        ){                        
            setIsValid(false);
            return false;
        } 

        if(newEvent.resource.assignedActors
             && newEvent.resource.assignedActors.entries && (newEvent.resource.assignedActors.entries as EventActor[]).filter(x => !x.actorId).length > 0) {            
            setTimesheetError("One or more entries is missing an actor");
            setIsValid(false);
            return false;
        } else {
            setTimesheetError("");
        }

        if(newEvent.resource.eventType === EventTypes.Job){
            //If there are any event actors selected, they should all contain complete data.
            if(
                eventActors !== null && 
                eventActors.length > 0 && 
                eventActors.filter((x: EventActor) => 
                    x.actorId == null || x.actorName == null || x.actorId === -1
                ).length > 0
            ){            
                return false;
            }
        }
        

        if(newEvent.resource.eventType === EventTypes.ActorVisit){
            if(
                newEvent.resource.registrantId != null ||
                newEvent.resource.actorName == null ||
                newEvent.resource.actorId == null
            ) {
                setIsValid(false);
                return false;
            }
        }

        //Photography events
        if(newEvent.resource.eventType === EventTypes.Photography) {            
            if(
                (newEvent.resource.actorId == null && newEvent.resource.registrantId == null) || 
                newEvent.resource.actorName === null ||
                (newEvent.resource.actorId != null && newEvent.resource.registrantId != null
            )){                
                setIsValid(false);
                return false;
            }
        }
                
        setIsValid(true);
        return true;


    }, [selectedEventType, newEvent.start, newEvent.end, newEvent.allDay, newEvent.title, newEvent.resource.actorId, newEvent.resource.registrantId, newEvent.resource.assignedActors]);

    const handleSubmit = () => {
        if (!validate()) return;

        if(newEvent.resource.eventType === EventTypes.Availability){
            dispatchAvailabilityUpdate();
            return;
        }

        var submitEvent = {
            ...newEvent
        }
        if(submitEvent.resource && submitEvent.resource.job != null && submitEvent.resource.jobId != null) {
            submitEvent.resource.job = null;
        }

        if(newEvent.resource !== null && newEvent.resource.jobId !== null) {
            newEvent.resource.job = null;
        }
        if (!defaultEdit) {
            dispatch(updateCalendarEvent(submitEvent));
        } else {                    
            dispatch(createCalendarEvent(submitEvent));
        }
    };

    const dispatchAvailabilityUpdate = () => { 
        if((newEvent.start === undefined) || (newEvent.end === undefined)){            
            return;
        }

        var start = newEvent.start;
        var end = newEvent.end;
        if(!start || !end){
            return;
        }
        var dates = GetDaysArray(start, end);        
        dispatch(setAvailability({
            actorId: actor ? actor.id : -1,
            availabilities: dates.map(x => {
                return  { 
                    date: new Date(moment(x).utcOffset(0, true).format()),
                    available: newEvent.resource.isNA
                } as Availability
            })
        }));
    }


    const handleDeleteEvent = (id: number) => {
        dispatch(deleteCalendarEvent({ Id: id }));
    };

    const handleJobSelection = (job: Job | null) => {
        setSelectedJob(job);
        setNewEvent({
            ...newEvent,
            resource: {
                ...newEvent.resource,
                job: job,
                jobId: job?.id ?? null
            }
        });
    }

    const handleEventTypeSelection = (eventType: number) => {       
        const eventTypeId = getEventTypeId(eventTypes[eventType]);                                     
        const newEnd = [EventTypes.ActorVisit, EventTypes.Photography].includes(eventTypeId as number) && event.start != null ? new Date(newEvent.start!.toDateString()) : newEvent.end            
        //Reset everything but the base event values
        setNewEvent({
            ...event,
            start: newEvent.start,
            end: newEnd,
            allDay: newEvent.allDay,
            title: newEvent.title,
            resource: manageEventResourcesOnChange(eventTypeId)
        });
        setSelectedEventType(eventType);
    }

    const manageEventResourcesOnChange = (eventTypeId: number) => {
        var newResource: any = {
            eventType: eventTypeId,
            notes: newEvent.resource.notes
        };

        if(defaultEdit) {
            return newResource;
        }

        //If there was an original eventType different to what is selected, we should clean up the event object
        if (event.resource != null && event.resource.eventType === eventTypeId){
           newResource = {
                ...event.resource,
                notes: newEvent.resource.notes
           }
        } else if(event.resource != null) {
            newResource = {
                eventType: eventTypeId,
                notes: newEvent.resource.notes,
                eventId: event.resource.eventId
            }
        }        
        return newResource;

    }

    useEffect(() => {        
        validate();
    }, [newEvent.start, newEvent.end, newEvent.resource, newEvent.allDay, newEvent.title, selectedEventType, validate]);

    return <>
        <OverlayBg onClick={(_) => setSelectedEvent(null)} styles={{left: isNavOpen ? "0px" : "88px"}}></OverlayBg>;

        { user?.role === "Admin" &&
          <AdminEventModalContent 
            dismiss={() => {
                handleDiscard();
                setSelectedEvent(null)
            }}
            discard={handleDiscard}
            submit={handleSubmit}
            defaultEdit={defaultEdit}
            editMode={editMode}
            setEditMode={(isEditMode: boolean) => setEditMode(isEditMode)} 
            event={newEvent} 
            setEvent={setNewEvent}
            deleteEvent={handleDeleteEvent}
            user={user}
            validate={validate}
            isValid={isValid}
            clearJob={clearJob}
            selectedJob={selectedJob}
            setSelectedJob={handleJobSelection}  
            timesheetError={timesheetError}
            selectedEventType={selectedEventType}
            setSelectedEventType={handleEventTypeSelection}   
            eventTypes={eventTypes}       
          />  
        }

        { user?.role === "Actor" &&
            <ActorEventModalContent 
                dismiss={() => setSelectedEvent(null)}
                discard={handleDiscard}
                submit={handleSubmit}
                defaultEdit={defaultEdit}
                editMode={editMode}
                setEditMode={(isEditMode: boolean) => setEditMode(isEditMode)} 
                event={newEvent} 
                setEvent={setNewEvent}
                deleteEvent={handleDeleteEvent}
                user={user}
                validate={validate}
                isValid={isValid}           
            />  
        }

    </>
};

export default NewCalendarEventModal;
