import { SearchJobsRequest, SearchJobsResponse, CreateJobRequest, CreateJobResponse, MarkInvoicedCommand, MarkInvoicedResponse } from "../model/jobModel";
import { apiCall } from "./sharedAsync";

export const apiSearchJobs = async (payload: SearchJobsRequest, token: string, rejectWithValue: (value: string) => void) => {
    return await apiCall<SearchJobsResponse, SearchJobsRequest>(token, "/job/search?SearchTerm=" + payload.searchTerm, "GET", rejectWithValue, "Unable to load jobs");
}

export const apiCreateJob = async (payload: CreateJobRequest, token: string, rejectWithValue: (value: string) => void) => {
    return await apiCall<CreateJobResponse, CreateJobRequest>(token, "/job/create", "POST", rejectWithValue, "Unable to create job.", payload);
}

export const apiMarkInvoiced = async (payload: MarkInvoicedCommand, token: string, rejectWithValue: (value: string) => void) => {
    return await apiCall<MarkInvoicedResponse, MarkInvoicedCommand>(token, "/job/invoiced", "POST", rejectWithValue, "Unable to update job invoiced status.", payload);
}