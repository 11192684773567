import React, { useEffect, useMemo, useState } from "react";
import { Calendar, DateRange, Event, SlotInfo, ToolbarProps, momentLocalizer } from "react-big-calendar";
import styled from "styled-components";
import moment from "moment";
import PageContainer from "../../layout/PageContainer";
import {
    getCalendarEvents,
    resetDeleteError,
    resetDeleteSuccess,
    resetUpdateError,
    resetUpdateSuccess,
} from "../../../store/actions/calendarActions";
import {
    calendarEventSelector,
    calendarLoadingSelector,
    deleteCalendarEventSuccessSelector,
    updateCalendarEventSuccessSelector,
} from "../../../store/selectors/calendarSelector";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import Button from "react-bootstrap/Button";
import { GetNextMonthEnd, GetPrevMonthStart } from "../../../utility/date";
import { loggedInUserSelector } from "../../../store/selectors/authSelector";
import { editActorSelector } from "../../../store/selectors/actorSelector";
import NewCalendarEventModal from "../../elements/Calendar/NewCalendarEventModal";
import EventTypes from "../../../model/EventTypes";
import { useTheme } from "@mui/material";
import { useWindowDimension } from "../../../utility/windowSize";
import MobileToolbar from "./MobileToolbar";
import PageHeaderProps from "../../../model/PageHeaderProps";
import LoadingOverlay from "../../elements/LoadingOverlay";
import CalendarToolbar from "../../elements/Calendar/subcomponents/CalendarToolbar";
import { PGEvent } from "../../../store/model/calendarModel";

const CreateEventButton = styled(Button)`
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    color: #fff;
    z-index: 999;
`;

const localizer = momentLocalizer(moment);

const CalendarPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const events = useAppSelector(calendarEventSelector);
    const updateSuccess = useAppSelector(updateCalendarEventSuccessSelector);
    const deleteSuccess = useAppSelector(deleteCalendarEventSuccessSelector);
    const loading = useAppSelector(calendarLoadingSelector);
    const actor = useAppSelector(editActorSelector);
    const user = useAppSelector(loggedInUserSelector);
    const [selectedEvent, setSelectedEvent] = useState<PGEvent | null>(null);
    const [showInvoiceStatus, setShowInvoiceStatus] = useState(false);
    const dimensions = useWindowDimension(100);

    const handleRangeChange = (range: Date[] | {start: Date, end: Date}) => {        
        if(Array.isArray(range)){
            const rangeStart = new Date(range[0]);          
            const rangeEnd = new Date(range[range.length - 1]);
            rangeStart.setHours(0, 0, 0);
            rangeEnd.setHours(23, 59, 59);
            dispatch(getCalendarEvents({ startRange: rangeStart, endRange: rangeEnd}));
            dispatch(resetDeleteError());
            dispatch(resetUpdateError());
            return;
        }

        const rangeStart = new Date(range.start);
        const rangeEnd = new Date(range.end);

        rangeStart.setHours(0, 0, 0);
        rangeEnd.setHours(23, 59, 59);
        dispatch(getCalendarEvents({ startRange: rangeStart, endRange: rangeEnd}));
        dispatch(resetDeleteError());
        dispatch(resetUpdateError());
    }

    const handleSlotSelection = (info: SlotInfo) => {
        if(user?.role === 'Actor') {
            setSelectedEvent({
                title: user?.role === "Actor" ? `Availability - ${actor?.firstName + " " + actor?.lastName}` : "",
                allDay: true,
                start: info.slots[0],
                end: info.slots[info.slots.length - 1],
                resource: { 
                    isNA: false, 
                    eventType: EventTypes.Availability,
                    slots: info.slots
                }
            });
        } 

        if(user?.role === "Admin"){
            setSelectedEvent({
                title: "",
                allDay: false,
                start: info.slots[0],
                end: info.slots[0],
                resource: {                     
                    eventType: EventTypes.Job                    
                }
            });
        }
    }

    useEffect(() => {      
        const rangeStart = GetPrevMonthStart(new Date());      
        const rangeEnd = GetNextMonthEnd(new Date());
        rangeStart.setHours(0, 0, 0);
        rangeEnd.setHours(11, 59, 59);    
        dispatch(getCalendarEvents({ startRange: rangeStart, endRange: rangeEnd}));
        dispatch(resetDeleteError());
        dispatch(resetUpdateError());
    }, [dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            setSelectedEvent(null);
            dispatch(resetUpdateSuccess());
        }
    }, [dispatch, updateSuccess]);

    useEffect(() => {
        if (deleteSuccess) {
            setSelectedEvent(null);
            dispatch(resetDeleteSuccess());
        }
    }, [dispatch, deleteSuccess]);

    const { views } = useMemo(() => {
        if(dimensions.width <= theme.breakpoints.values.md){
            return {            
                views: {            
                    //day: true,
                    agenda: true,
                    //month: true,
                    //week: true
                },
                otherProps: {
                    defaultView: "agenda"
                }
            }
        } 
        return {
            views: {            
                day: true,
                agenda: true,
                month: true,
                week: true
            },
            otherProps: {
                defaultView: "month"
            }
        }
                  
    }, [dimensions])
   
    return (
        <PageContainer>        
            {dimensions.width < theme.breakpoints.values.lg &&
                <Calendar  
                    views={views}                        
                    localizer={localizer}   
                    defaultView="agenda"
                    events={[
                        ...(events ?? []).map((event) => {
                            return {
                                title: event.title,
                                start: event.start,
                                end: event.end,
                                allDay: event.allDay,
                                resource: event.resource,
                                eventCode: event.eventCode
                            };
                        }),
                    ]}   
                    components={{
                        toolbar: (toolbar) => <MobileToolbar toolbar={toolbar} isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} setSelectedEvent={setSelectedEvent} user={user} actor={actor}/>
                    }}             
                    startAccessor="start"
                    endAccessor="end"
                    onSelectEvent={(event) => {
                  
                        setSelectedEvent(event)
                    }}
                    onSelectSlot={(info) => handleSlotSelection(info)}
                    eventPropGetter={(event: Event, start: Date, end: Date, isSelected: boolean) => { 
                        if(!event.resource || (event.resource.isNA === undefined || event.resource.isNA === null)){
                            return {};
                        }

                        var isNa = event.resource.isNA                    
                        return {
                            className: isNa ? "na" : "available"
                        }
                    }}
                    selectable={true}
                    onRangeChange={handleRangeChange}
                    style={{ 
                        width: "100%", 
                        height: "100vh",                        
                     }}
                    step={15}
                    timeslots={2}  
                    formats={{
                        agendaHeaderFormat: (range: DateRange) => {
                            return range.start.toLocaleDateString("EN-AU") + " - " + range.end.toLocaleDateString("EN-AU");
                        },
                        agendaDateFormat: (date: Date) => {
                            return date.getDate() + "/" + (date.getMonth() + 1);
                        },
                        agendaTimeRangeFormat: (dateRange: DateRange) => {
                            return dateRange.start && dateRange.end ? new Date(dateRange.start).toLocaleTimeString("en-AU", { hour12: true, hour: "numeric", minute: "2-digit"}) + "\n - " + new Date(dateRange.end).toLocaleTimeString("en-AU", { hour12: true, hour: "numeric", minute: "2-digit"}) : "";
                        }
                    }}              
                />                            
            }
            {dimensions.width >= theme.breakpoints.values.lg &&
                <Calendar  
                    views={views}                        
                    localizer={localizer}   
                    defaultView="month"               
                    events={[
                        ...(events ?? []).map((event) => {
                            return {
                                title: event.title,
                                start: event.start,
                                end: event.end,
                                allDay: event.allDay,
                                resource: event.resource,
                                eventCode: event.eventCode
                            };
                        }),
                    ]}   
                    components={{toolbar: (props: ToolbarProps) => <CalendarToolbar {...props} showInvoiceStatus={showInvoiceStatus} setShowInvoiceStatus={(status: boolean) => setShowInvoiceStatus(status)} isActor={user?.role === "Actor"} />}}             
                    startAccessor="start"
                    endAccessor="end"
                    onSelectEvent={(event) => {                                           
                        setSelectedEvent(event)
                    }}
                    onSelectSlot={(info) => handleSlotSelection(info)}
                    eventPropGetter={(event: Event, start: Date, end: Date, isSelected: boolean) => { 
                        if(event.resource && event.resource.eventType === EventTypes.Job && event.resource.job !== undefined && event.resource.job !== null && showInvoiceStatus){
                            return {
                                className: event.resource.job?.isInvoiced !== null ? "invoiced" : "not-invoiced"
                            }
                        }
                        if(!event.resource || (event.resource.isNA === undefined || event.resource.isNA === null)){
                            return {};
                        }

                        var isNa = event.resource.isNA                    
                        return {
                            className: isNa ? "na" : "available"
                        }
                    }}
                    selectable={true}
                    onRangeChange={handleRangeChange}
                    style={{ width: "100%", height: "100vh" }}
                    step={15}
                    timeslots={2}  
                    formats={{
                        agendaHeaderFormat: (range: DateRange) => {
                            return range.start.toLocaleDateString("EN-AU") + " - " + range.end.toLocaleDateString("EN-AU");
                        }
                    }}               
                /> 
            }

            {selectedEvent && (      
                <NewCalendarEventModal 
                    event={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    isNavOpen={isNavOpen}
                    defaultEdit={
                        user?.role !== "Actor" ?
                        (selectedEvent.resource && selectedEvent.resource.eventId
                            ? false
                            : true) :
                        (selectedEvent.resource.eventType == EventTypes.Availability ?
                            true : 
                            false)
                    }
                />
            )}

            {!selectedEvent && dimensions.width >= theme.breakpoints.values.lg && (
                <CreateEventButton
                    onClick={(e: React.MouseEvent) =>
                        setSelectedEvent({
                            title: user?.role === "Actor" ? `Availability - ${actor?.firstName + " " + actor?.lastName}` : "",
                            allDay: user?.role === "Actor",
                            resource: user?.role === "Actor" ? 
                                { 
                                    isNA: false,
                                    eventType: EventTypes.Availability,
                                    actorId: actor?.id
                                } :
                                user?.role === "Admin" ? 
                                { eventType: EventTypes.Job } :
                                null                              
                        })
                    }
                >
                   {user?.role === "Admin" ? "Create Event" : "Add Availability" }
                </CreateEventButton>
            )}
            {loading && <LoadingOverlay text="Loading events..." />}
        </PageContainer>        
    );
};

export default CalendarPage;
