
interface EventActor {
    id?: number;
    actorId?: number;
    actorName?: string;
    eventTimesheetId: number;
    comments?: string;    
    isExisting: boolean;
    hasActorConfirmed: boolean;
}


export function sortByActorSurname(a: string, b: string) {    
    var aName = a.trim().split(" ");
    var bName = b.trim().split(" ");    
    return aName[aName.length - 1].toLocaleLowerCase().localeCompare(bName[bName.length - 1].toLocaleLowerCase())
}

export default EventActor;

