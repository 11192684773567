import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiBookActorGroup, apiCreateActorGroup, apiDeleteActorGroup, apiGetActorGroups, apiGetActorGroupsData, apiGetCompCard, apiGetEventCard, apiSearchActorGroups, apiUpdateActorGroup } from "../async/actorGroupAsync";
import { ActorGroupDetail, CreateActorGroupRequest, CreateActorGroupResponse, DeleteActorGroupRequest, GetActorGroupsDataRequest, GetActorGroupsDataResponse, GetActorGroupsRequest, GetActorGroupsResponse, GetCompCardRequest, GetCompCardResponse, GetEventCardRequest, GetEventCardResponse, IBookActorGroupRequest, IBookActorGroupResponse, IRenameActorGroupRequest, SearchActorGroupsRequest, SearchActorGroupsResponse, UpdateActorGroupResponse, UpdateActorGroupsRequest } from "../model/actorGroupModel";

export const getActorGroups = createAsyncThunk<GetActorGroupsResponse, GetActorGroupsRequest, { state: RootState, rejectValue: string}>("actorGroup/GET_ACTOR_GROUPS", async (payload, { getState, rejectWithValue }) => {
    const token: string | undefined = getState().auth.user?.token;
    return apiGetActorGroups(payload, token ?? "", rejectWithValue);
});

export const getActorGroupsData = createAsyncThunk<GetActorGroupsDataResponse, GetActorGroupsDataRequest, { state: RootState, rejectValue: string}>("actorGroup/GET_ACTOR_GROUPS_DATA", async (payload, { getState, rejectWithValue }) => {
    const token: string | undefined = getState().auth.user?.token;
    return apiGetActorGroupsData(payload, token ?? "", rejectWithValue);
});

export const searchActorGroups = createAsyncThunk<SearchActorGroupsResponse, SearchActorGroupsRequest, {state: RootState, rejectValue: string}>("actorGroup/SEARCH_ACTOR_GROUPS", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiSearchActorGroups(payload, token ?? "", rejectWithValue);
});

export const createActorGroup = createAsyncThunk<CreateActorGroupResponse, CreateActorGroupRequest, { state: RootState, rejectValue: string}>("actorGroup/CREATE_ACTOR_GROUP", async (payload, {getState, rejectWithValue}) => {
    const token: string | undefined = getState().auth.user?.token;
    return apiCreateActorGroup(payload, token ?? "", rejectWithValue);
});

export const updateActorGroup = createAsyncThunk<UpdateActorGroupResponse, UpdateActorGroupsRequest, {state: RootState, rejectValue: string}>("actorGroup/UPDATE_ACTOR_GROUP", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiUpdateActorGroup(payload, token ?? "", rejectWithValue);
});

export const renameActorGroup = createAsyncThunk<UpdateActorGroupResponse | undefined, IRenameActorGroupRequest, {state: RootState, rejectValue: string}>("actorGroup/RENAME_ACTOR_GROUP", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    const actorGroupIndex = getState().actorGroup.actorGroups.findIndex(group => group.actorGroupId === payload.actorGroupId);
    if(actorGroupIndex === -1) {
        rejectWithValue("No such actor group");
        return;
    } 

    var actorGroup = getState().actorGroup.actorGroups[actorGroupIndex];


    return apiUpdateActorGroup(
        {
            actorGroupId: actorGroup.actorGroupId,
            actorGroupName: payload.actorGroupName,
            entries: actorGroup.entries,
            bookingDates: actorGroup.bookingDates                        
        }, 
        token ?? "", 
        rejectWithValue
    );
});

export const deleteActorGroup = createAsyncThunk<void, DeleteActorGroupRequest, {state: RootState, rejectValue: string}>("actorGroup/DELETE_ACTOR_GROUP", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiDeleteActorGroup(payload, token ?? "", rejectWithValue);
});

export const getCompCard = createAsyncThunk<GetCompCardResponse, GetCompCardRequest, {rejectValue: string}>("actorGroup/GET_COMP_CARD", async (payload, {rejectWithValue}) => {
    return await apiGetCompCard(payload, rejectWithValue);
});

export const getEventCard = createAsyncThunk<GetEventCardResponse, GetEventCardRequest, {rejectValue: string}>("actorGroup/GET_EVENT_CARD", async (payload, {rejectWithValue}) => {
    return await apiGetEventCard(payload, rejectWithValue);
});

export const bookActorGroup = createAsyncThunk<IBookActorGroupResponse, IBookActorGroupRequest, {state:RootState, rejectValue: string}>("actorgroup/BOOK_ACTOR_GROUP", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiBookActorGroup(payload, token ?? "",  rejectWithValue);
});

export const setActorGroupPageSize = createAction("actorGroup/SET_PAGE_SIZE", (payload: { size: number}) => ({ payload: payload}));

export const setActorGroupSort = createAction("actorGroup/SET_SORT", (payload: { column: string | null, order: string | null}) => ({payload: payload}));

export const clearSaveComplete = createAction("actorGroup/CLEAR_SAVE_COMPLETE");

export const setCurrentActorGroup = createAction("actorGroup/SET_CURRENT_GROUP", (payload: ActorGroupDetail) => ({ payload: payload}));

export const clearCurrentActorGroup = createAction("actorGroup/CLEAR_CURRENT_GROUP");

export const clearCompCardError = createAction("actorGroup/CLEAR_COMP_CARD_ERROR");

export const clearExportData = createAction("actorGroup/CLEAR_EXPORT");

export const resetActorGroupSlice = createAction("actorGroup/RESET_SLICE");

export const resetRenameState = createAction("actorGroup/RESET_RENAME_STATE");