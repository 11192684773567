import { momentLocalizer, Navigate, ToolbarProps } from 'react-big-calendar';
import Switch from '@mui/material/Switch';

interface CalendarToolbarProps extends ToolbarProps {
    setShowInvoiceStatus: (showInvoiceStatus: boolean) => void;
    showInvoiceStatus: boolean;
    isActor: boolean;
}

const CalendarToolbar: React.FC<CalendarToolbarProps> = ({onView, onNavigate, label, view, showInvoiceStatus, setShowInvoiceStatus, isActor}) => {    
    return (<div className="rbc-toolbar">
        <div className="rbc-btn-group">
            <button type="button" onClick={() => onNavigate('TODAY')}>Today</button>
            <button type="button" onClick={() => onNavigate('PREV')}>Back</button>
            <button type="button" onClick={() => onNavigate('NEXT')}>Next</button>
        </div>
        <div className="rbc-toolbar-label">{label}</div>        
        {
        !isActor &&
            <div className="rbc-switch">
                {showInvoiceStatus ? "Invoice View" : "Standard View" }
                <Switch color='secondary' checked={showInvoiceStatus} onChange={(e) => setShowInvoiceStatus(e.currentTarget.checked)} />
            </div>
        }
        <div className="rbc-btn-group">
            <button className={view === 'day' ? "rbc-active" : "" } type="button" onClick={ () => onView('day')}>Day</button>
            <button className={view === 'agenda' ? "rbc-active" : "" } type="button" onClick={ () => onView('agenda')}>Agenda</button>
            <button className={view === 'month' ? "rbc-active" : "" } type="button" onClick={ () => onView('month')}>Month</button>
            <button className={view === 'week' ? "rbc-active" : "" } type="button" onClick={ () => onView('week')}>Week</button>            
        </div>
    </div>)
}

export default CalendarToolbar; 